.experience {
    margin-top: 100px;
}
.downloadCV {
    display: flex;
    justify-content: center;
    margin-bottom: -100px;
}

.downloadCV a {
    margin-top: 20px;
    text-decoration: none;
}
.downloadCV a span {
    display: none;
}

u {
    text-decoration: none;
    align-items: center;
    justify-content: center;
}

.resumeTitle {
    align-items: center;
    justify-content: center;
    display: flex;
}

.resumeTitle h1 {
    background-image: linear-gradient(to left,#21325e 0%, #2E4684 21%, #3B5AAA 52%, #2E4684 78%, #21325e 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

.experience h2 {
    background-image: linear-gradient(to left,#21325e 0%, #2E4684 21%, #3B5AAA 52%, #2E4684 78%, #21325e 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

@media only screen and (max-width: 719px) {
    .downloadCV a img {
    display: none;
    }
    .downloadCV a span {
        display: flex;
    }
    .downloadCV a {
        background: #131313;
        color: #131313;
        font-size: 18px;
        font-weight: 600;
        padding: 8px 16px;
        margin-top: 20px;
        border-radius: 6px;
        border: 2px solid #131313;
        transition: all 0.3s ease;
        background-image: linear-gradient(to left,#21325e 0%, #2E4684 21%, #3B5AAA 52%, #2E4684 78%, #21325e 100%);
        text-decoration: none;
    }
    
    .downloadCV a:hover {
        background: none;
        color: #21325e;
        background-image: linear-gradient(to left,#21325e 0%, #2E4684 21%, #3B5AAA 52%, #2E4684 78%, #21325e 100%);
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
    }
}

@media only screen and (max-width: 450px) {
    .downloadCV {
        margin-bottom: 20px;
    }
}
/* 
.vertical-timeline-element--education:hover {
    background-color: #131313;
} */