.project {
    width: 100%;
    /* height: calc(100vh - 100px); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
  }
  
  .project h1 {
    margin-top: 50px;
    color: #3e497a;
    font-size: 35px;
  }
  .project img {
    width: 700px;
    border-radius: 10px;
  }
  .project p b {
    color: #3e497a;
  }
  
  .project p {
    font-size: 30px;
    color: #131313;
    width: 70%;
    display: flex;
    justify-content: center; 
    /* align-items: center; */
    flex-direction: row;
    text-align: center;
  }
  /* .project p h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  } */
  
  .project svg {
    font-size: 60px;
    color: #3e497a;
  }
  
  .project img {
    height: 350px;
    width: 650px;
  }

  .icons {
    color: #131313;
  }
  /* @media only screen and (max-width: 800px) {
    .project img {
      height: 400px;
      width: 600px;
    }
  }
  @media only screen and (max-width: 400px) {
    .project img {
      height: 150px;
      width: 300px;
    }
  } */
  @media only screen and (max-width: 1300px) {
    .project img {
      height: 466px;
      width: 800px;
      border-radius: 15px;
    }
  }
  @media only screen and (max-width: 1000px) {
    .project img {
      height: 408px;
      width: 700px;
    }
  }
  @media only screen and (max-width: 800px) {
    .project img {
      height: 350px;
      width: 600px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .project img {
      height:35%;
      width: 100%;
      border-radius: 20px;
    }
    .project p {
      font-size: 40px;
      color: #3e497a;
      margin-left: 200px;
    }
  }

  @media only screen and (max-width: 450px) {
    .project p {
      font-size: 30px;
      margin-left: 0px;
    }
  }