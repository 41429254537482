
.projects {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #3e497a;
    margin-top: 100px;
    background-image: linear-gradient(to left,#21325e 0%, #2E4684 21%, #3B5AAA 52%, #2E4684 78%, #21325e 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
  .projects .projectTitle {
    text-align: center;
    color: #3e497a;
    font-size: 60px;
    font-weight: bolder;
  }
  
  .projectList {
    width: 70vw;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
  }
  
  /* MENU ITEM STYLING */
  
  .projectItem {
    border-radius: 15px;
    width: 300px;
    height: 300px;
    margin: 40px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  .projectItem:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    cursor: pointer;
  }
  
  .projectItem .bgImage {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .projectItem h1 {
    font-size: 25px;
    color: #131313;
    /* background-color: #3e497a;
    background-image: linear-gradient(to left,#21325e 0%, #2E4684 21%, #3B5AAA 52%, #5473C4 78%, #6480ca 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent; */
  }

  @media only screen and (max-width: 1300px) {
    .projectList {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projectList {
      grid-template-columns: 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projectList {
      width: 100%;
    }
    .projectItem {
      width: 300px;
      height: 300px;
    }
  }