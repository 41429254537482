*{
  box-sizing: border-box;
  text-decoration: none;
}

.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #c1c1c1;
}

body {
  padding: 0;
  margin: 0;
}